// Queries and mutations unique to the Dashboard module
import { gql } from 'apollo-boost';

// Dashboard data
export const MAIN_DASHBOARD_QUERY = gql`
query currentStudent {
    currentStudent {
        id
        name
        boomerAvatarUrl
        updatedAt
        school {
            id
            name
        }
        entries {
            id
            text
            submittedAt
        }
        books {
            id
            currentChapter
            isSingleChapter
            title
            completedAt
            status
            chapters {
                id
                orderid
                title
                status
                studentEntries {
                  id
                  text
                }
            }
            bookTemplate {
                id
                name
                coverUrl
            }
            classPack {
                id
                isWritingActivity
            }
        }
        selectedBookTemplate {
            id
            name
        }
    }
}
`;


export const FILTERED_TEMPLATES = gql`
query filteredBookTemplates($isIspChoice: Boolean) {
    filteredBookTemplates(isIspChoice: $isIspChoice) {
        id
        name
        chapters {
            id
            content
            title
        }
    }
}
`;

export const ADD_BOOK_TEMPLATE_CHOICE = gql`
mutation addBookTemplateChoice($studentId: ID!, $bookTemplateId: ID!) {
    addBookTemplateChoice(studentId: $studentId, bookTemplateId: $bookTemplateId ) {
        errors 
        bookTemplateChoice {
            id
            bookTemplate {
                id
            }
        }
    }
}
`;