import React, { Fragment, useContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import { Loader } from '@axeedge/go-pupil-components';
import { BOOK_STATUS } from '@axeedge/go-shared-utils';
import { Link } from '@reach/router';
import { MAIN_DASHBOARD_QUERY } from './services/graphql';
import { AuthContext } from '../../services/user/AuthProvider';
import styles from './Dashboard.module.scss';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import Todo from './scenes/Todo';
import Books from './scenes/Books';
import Writing from './scenes/Writing';
import boomer from '../../images/loader-boomer.png';
import ispLogo from '../../images/isp_logo.png';
import { FILTERED_TEMPLATES } from './services/graphql';

const TABS = {
    todo: 0,
    books: 1,
    writing: 2
};

const Dashboard = () => {

    const [selectedTab, setSelectedTab] = useState(TABS.todo);


    const { data, error, loading } = useQuery(MAIN_DASHBOARD_QUERY, {
        fetchPolicy: 'cache-and-network'
    });
    const { data: data2, loading: loading2, error: error2 } = useQuery(FILTERED_TEMPLATES, {
        variables: {
            isIspChoice: true
        }
    });

    const { currentUser: user } = useContext(AuthContext);

    if (loading || error || loading2 || error2) {
        return (
            <div className={styles.dashboard}>
                <div className={styles.dashboardMain}>
                    <div className='content-panel'>
                        {error && <p>{error.message}</p>}
                        {loading && <Loader />}
                    </div>
                </div>
                <div className={styles.dashboardSide}>
                    <div className={styles.dashboardSidePanel}>
                        <img src={user.boomerAvatarUrl ? `${user.boomerAvatarUrl}?v=${user.updatedAt.replace(/\s/g, '')}` : boomer} className={styles.dashboardSidePanelAvatar} />
                        <h2 className={styles.dashboardSidePanelTitle}>Hi, {user.firstName}</h2>
                        <p>Welcome to BoomWriter!</p>
                    </div>
                </div>
            </div>
        )
    }

    const getBooks = () => {
        const filteredBooks = [];
        if (selectedTab === TABS.todo) {
            data.currentStudent.books.forEach(book => {
                if (book.status < BOOK_STATUS.completed || (book.completedAt && moment(book.completedAt).isBefore(moment().subtract(2, 'days').startOf('day')))) {
                    filteredBooks.push(book);
                }
            })
        }
        if (selectedTab === TABS.books) {
            data.currentStudent.books.forEach(book => {
                if (book.status >= BOOK_STATUS.completed && !book.classPack?.isWritingActivity) {
                    filteredBooks.push(book);
                }
            })
        }
        // if (selectedTab === TABS.writing) {
        //     data.currentStudent.books.forEach(book => {
        //         if (book.classPack.isWritingActivity) {
        //             filteredBooks.push(book);
        //         }
        //     })
        // }
        return filteredBooks;
    }

    if (data && data.currentStudent && data.currentStudent.books && data2.filteredBookTemplates) {
        return (
            <div className={styles.dashboard}>
                <div className={styles.dashboardMain}>
                    <div className='content-panel'>
                        <Tabs noBorder={true}>
                            <Tab active={selectedTab === TABS.todo} onClick={() => setSelectedTab(TABS.todo)}>To do</Tab>
                            <Tab active={selectedTab === TABS.books} onClick={() => setSelectedTab(TABS.books)}>My Books</Tab>
                            {/* <Tab active={selectedTab === TABS.writing} onClick={() => setSelectedTab(TABS.writing)}>My Writing</Tab> */}
                        </Tabs>
                        {selectedTab === TABS.todo && <Todo books={getBooks()} />}
                        {selectedTab === TABS.books && <Books books={getBooks()} />}
                        {/* {selectedTab === TABS.writing && <Writing books={getBooks()} />} */}
                    </div>
                </div>
                <div className={styles.dashboardSide}>
                    <div className={styles.dashboardSidePanel}>
                        <img src={user.boomerAvatarUrl ? `${user.boomerAvatarUrl}?v=${user.updatedAt.replace(/\s/g, '')}` : boomer} className={styles.dashboardSidePanelAvatar} />
                        {selectedTab === TABS.todo && (
                            <Fragment>
                                <h2 className={styles.dashboardSidePanelTitle}>Hi, {user.firstName}</h2>
                                <p>Welcome to BoomWriter!</p>
                            </Fragment>
                        )}
                        {selectedTab === TABS.books && (
                            <Fragment>
                                <h2 className={styles.dashboardSidePanelTitle}>OMG! You are published.</h2>
                                <p>These are your published books, written by you and your class.</p>
                            </Fragment>
                        )}
                        {selectedTab === TABS.writing && (
                            <Fragment>
                                <h2 className={styles.dashboardSidePanelTitle}>Writing Tasks.</h2>
                                <p>You can find all your writing tasks here.</p>
                            </Fragment>
                        )}
                    </div>
                    {data.currentStudent?.selectedBookTemplate ?
                        <div className={styles.ispBanner}>
                            <p>The book template you selected for the next part of the IPS Writing project is:</p>
                            <p className='heavy'>{data.currentStudent.selectedBookTemplate.name}</p>
                        </div>
                        :
                        data2.filteredBookTemplates?.length > 0 &&
                        <Link to='/selectStory' className={styles.ispBanner}>
                            <p>Choose your story start for the next part of the ISP Writing Project!</p>
                            <img width={240} src={ispLogo} alt='isp logo' />
                        </Link>
                    }
                </div>
            </div>
        )
    }
    return null;
}

export default Dashboard;
